/* eslint-disable @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from 'react';
import { TOAST_VARIANTS, Toast, TOAST_POSITIONS } from 'cdk-radial';
import { SuccessToastProps } from './types';

const SuccessToast = ({
  successMessage,
  setToastDisable
}: SuccessToastProps): JSX.Element => {
  const [showToast, setShowToast] = useState<boolean>(true);
  const [hovered, setHovered] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!hovered) {
      timer = setTimeout(() => {
        setShowToast(false);
        if (setToastDisable) {
          setToastDisable([]);
        }
      }, 6000);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return (
    <>
      {showToast && (
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Toast
            actions={[
              {
                onClick: () => {
                  setShowToast(false);
                  if (setToastDisable) {
                    setToastDisable([]);
                  }
                },
                text: 'Dismiss'
              }
            ]}
            id="expandable-toast"
            content={successMessage}
            position={TOAST_POSITIONS.FIXED}
            variant={TOAST_VARIANTS.POSITIVE}
          />
        </div>
      )}
    </>
  );
};

export default SuccessToast;
