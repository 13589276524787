/* eslint-disable @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-return */
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  MutableRefObject
} from 'react';
import { TOAST_VARIANTS } from 'cdk-radial';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { handleDownloadClick } from 'utils/utility';
import {
  setContentHeader,
  setTemplatDataRows,
  setTemplatDataRowField,
  setTemplateFieldExpansion,
  setTemplateRowHeight,
  handleDataRemoval,
  handleRemoveAll,
  setTemplateRolesAndStores
} from 'redux/slices/userImportSlice';
import { GridApi, RowNode } from 'ag-grid-community';
import { UserRole, UserStore } from 'redux/services/admin-service/types';
import useColumns from 'fixtures/grid/ReviewImport/columnDefinitions';
import schema from 'fixtures/grid/ReviewImport/fitlerSchema';
import {
  generateFilterSelectionObject,
  getEmptyTableMessage
} from 'helpers/tableHelper';
import { RootState } from 'redux/store';
import { setGlobalToast } from 'redux/slices/commonSlice';
import { ExpandableSuccessMessage } from 'components/common/ExpandableSuccessMessage';
import SuccessToast from 'components/ui/SuccessToast';
import { StyledStepThree, StyledStepThreeFilterContainer } from './styled';
import TableContainer from '../../../templates/TableLayout/TableContainer/TableContainer';
import FilterHeader from '../../../templates/TableLayout/FilterHeader/FilterHeader';
import ReviewImportHeader from '../../ReviewImports/ReviewImportHeader/ReviewImportHeader';
import TableContent from '../../../templates/TableLayout/TableContent/TableContent';
import TablePaging from '../../../templates/TableLayout/TablePaging/TablePaging';
import AssignRolesDialog from '../../common/AssignRolesModal/AssignRolesDialog';
import AssignStoresDialog from '../../common/AssignStoresModal/AssignStoresDialog';
import { messages } from './messages';

type SelectedRowsType = {
  id: string;
};
const StepThree = (): JSX.Element => {
  const intl = useIntl();
  const [columns] = useColumns();

  const [searchValue, setSearchValue] = useState('');
  const [gridApi, setGridApi] = useState<GridApi>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isAssignRolesDialogOpen, setIsAssignRolesDialogOpen] =
    useState<boolean>(false);
  const [isAssignStoresDialogOpen, setIsAssignStoresDialogOpen] =
    useState<boolean>(false);

  const displayedRowCount =
    gridApi && gridApi.getDisplayedRowCount
      ? gridApi.getDisplayedRowCount()
      : 0;
  const dispatch = useDispatch();

  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const paginationHeight = 85;
  const headerSectionHeight = 16;

  const { selectedRows, selectedRowsStepTwo } = useSelector(
    (state: RootState) => state.slices.userImportState
  );

  useEffect(() => {
    const navTitle = intl.formatMessage(messages.stepThreeHeader);
    dispatch(setContentHeader(navTitle));
  }, [dispatch, intl]);

  type RowHeightType = {
    data: {
      rowHeight: number;
    };
  };

  const minHeight = 80;
  const getRowHeight = useCallback(
    (params: RowHeightType) =>
      params.data.rowHeight > minHeight ? params.data.rowHeight : minHeight,
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowData = selectedRows || [];
  const rowDataStepTwo = selectedRowsStepTwo || [];

  const handleFieldChange = (
    rowIndex: number,
    field: string,
    value: UserRole[] | UserStore[]
  ) => {
    dispatch(setTemplatDataRowField({ rowIndex, field, value }));
  };
  useEffect(() => {
    if (gridApi && selectedRows) {
      gridApi.setRowData(selectedRows);
      gridApi.resetRowHeights();
    }
  }, [selectedRows, gridApi]);

  const filterSchema = [...schema];
  const [filterSelections, setFilterSelections] = useState<
    Record<string, Array<string | undefined>>
  >(generateFilterSelectionObject(filterSchema));

  const onRemoveFromImportList = () => {
    if (!gridApi?.getSelectedRows().length) {
      dispatch(
        setGlobalToast({
          content: intl.formatMessage(messages.removeUserWarning),
          variant: TOAST_VARIANTS.NEGATIVE,
          autoHide: true
        })
      );
    }
    const selectedRemRows = gridApi?.getSelectedRows() as SelectedRowsType[];
    const selectedRowIndices: Record<string, boolean> = selectedRemRows.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: true }),
      {}
    );

    const filteredRows = rowData.filter(({ id }) => !selectedRowIndices[id]);
    const filteredRowsStepTwo = rowDataStepTwo.filter(
      ({ id }) => !selectedRowIndices[id]
    );

    if (gridApi?.getSelectedRows().length) {
      const msg: number = gridApi?.getSelectedRows().length;
      dispatch(
        setGlobalToast({
          content: intl.formatMessage(messages.userRemovedWarning, { msg }),
          variant: TOAST_VARIANTS.POSITIVE,
          autoHide: true
        })
      );
    }
    dispatch(setTemplatDataRows({ filteredRows, filteredRowsStepTwo }));
  };
  const getRowNodeId = (row: Record<string, string>) => row.id;

  const onAssignRolesClick = () => {
    setIsAssignRolesDialogOpen(!isAssignRolesDialogOpen);
  };
  const onAssignStoresClick = () => {
    setIsAssignStoresDialogOpen(!isAssignStoresDialogOpen);
  };

  const [items, setItems] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState(0);

  const handleAssignment = (
    selectedItems: UserRole[] | UserStore[],
    field: string,
    keyToAccess: string
  ) => {
    if (selectedRows && gridApi && selectedItems?.length) {
      const selectedNodes: RowNode[] = gridApi.getSelectedNodes();
      const selectedNodesLength = selectedNodes.length;
      selectedNodes.forEach((row: RowNode) => {
        const { rowIndex } = row;
        dispatch(
          setTemplateRolesAndStores({
            rowIndex,
            field,
            value: selectedItems,
            keyToAccess
          })
        );
      });
      if (selectedNodesLength) {
        const itemsText: string[] = selectedItems.map(
          i => i.code || i.id
        ) as string[];
        setItems(itemsText);
        setSelectedUsers(selectedNodesLength);
      } else {
        dispatch(
          setGlobalToast({
            content: intl.formatMessage(messages.assignUserWarning),
            variant: TOAST_VARIANTS.NEGATIVE
          })
        );
      }
    }
  };

  const expandableSuccessMessage = intl.formatMessage(
    messages.assignUserSuccess,
    {
      selectedUserCount: selectedUsers
    }
  );

  const setRowFieldExpansion = (
    rowIndex: number,
    field: string,
    isCollapsed: boolean
  ) => {
    dispatch(
      setTemplateFieldExpansion({ rowIndex, field, value: isCollapsed })
    );
  };

  const setRowHeight = (rowIndex: number, rowHeight: number, field: string) => {
    dispatch(setTemplateRowHeight({ rowIndex, rowHeight, field }));
  };

  const handleFieldRemoval = (
    fieldIndex: number,
    rowIndex: number,
    field: string
  ) => {
    dispatch(handleDataRemoval({ fieldIndex, rowIndex, field }));
  };

  const handleRemoveAllData = (rowIndex: number, field: string) => {
    dispatch(handleRemoveAll({ rowIndex, field }));
  };

  const getSelectedRowsCount = gridApi?.getSelectedRows().length;

  return (
    <>
      {items.length > 0 && (
        <SuccessToast
          setToastDisable={setItems}
          successMessage={ExpandableSuccessMessage(
            expandableSuccessMessage,
            items
          )}
        />
      )}
      <StyledStepThree ref={containerRef}>
        <TableContainer showBorder>
          <StyledStepThreeFilterContainer>
            <FilterHeader
              filterSchema={filterSchema}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              filterSelections={filterSelections}
              setFilterSelections={setFilterSelections}
            />
            <ReviewImportHeader
              selectedItems={getSelectedRowsCount}
              onRemoveFromImportList={onRemoveFromImportList}
              onAssignRoles={onAssignRolesClick}
              onAssignStores={onAssignStoresClick}
              isDownloadable="true"
              onDownloadClick={(): void => handleDownloadClick(gridApi)}
              disableDownloadButton={displayedRowCount === 0}
            />
          </StyledStepThreeFilterContainer>
          <TableContent
            gridApi={gridApi}
            searchValue={searchValue}
            filterSelections={filterSelections}
            tableHeight={
              containerRef &&
              containerRef.current &&
              containerRef.current.clientHeight
                ? containerRef.current.clientHeight -
                  paginationHeight -
                  headerSectionHeight
                : 300
            }
            getRowHeight={getRowHeight}
            setGridApi={setGridApi}
            setCurrentPage={setCurrentPage}
            setTotalPages={setTotalPages}
            setTotalRows={setTotalRows}
            immutableData
            columns={columns}
            getRowNodeId={getRowNodeId}
            context={{
              handleFieldChange,
              setRowFieldExpansion,
              setRowHeight,
              handleFieldRemoval,
              handleRemoveAllData
            }}
            filterSchema={filterSchema}
            overlayNoRowsTemplateMessage={getEmptyTableMessage(
              searchValue,
              intl
            )}
          />
          <TablePaging
            gridApi={gridApi}
            currentPage={currentPage}
            totalPages={totalPages}
            totalRows={totalRows}
          />
        </TableContainer>
        {isAssignRolesDialogOpen && (
          <AssignRolesDialog
            isAssignRolesDialogOpen={isAssignRolesDialogOpen}
            closeAssignRolesDialog={() => setIsAssignRolesDialogOpen(false)}
            context={{
              handleAssignRoles: (selectedRoles: UserRole[]) =>
                handleAssignment(selectedRoles, 'roles', 'code')
            }}
          />
        )}

        {isAssignStoresDialogOpen && (
          <AssignStoresDialog
            isAssignStoresDialogOpen={isAssignStoresDialogOpen}
            closeAssignStoresDialog={() => setIsAssignStoresDialogOpen(false)}
            context={{
              handleAssignStores: (selectedStores: UserStore[]) =>
                handleAssignment(selectedStores, 'stores', 'id')
            }}
          />
        )}
      </StyledStepThree>
    </>
  );
};

export default StepThree;
