import { FullUser } from 'redux/services/identity-service/types';

const defaultUserData = {} as FullUser;

export const setWalkMeGlobalVariables = (
  userEnterpriseId: string,
  userData: FullUser = defaultUserData
): void => {
  if (userData?.fullUser) {
    const {
      fullUser: { user, stores }
    } = userData;

    const defaultStore = stores.find(store => store.defaultStore);

    const walkmeData = {
      appName: 'Admin',
      userName: user?.loginId,
      defaultEnterprise: userEnterpriseId,
      defaultStore: defaultStore?.id,
      user_access: {
        ADDATTRIBUTES: true,
        ADDFORMTYPE: true,
        ADMNDEPARTMENTPERM: true
      }
    };

    const windowObject = window as any;
    // eslint-disable-next-line no-underscore-dangle
    windowObject._walkmeConfig = { smartLoad: true, isContainedInIframe: true };
    windowObject.WALKME_USERID = walkmeData.userName;

    if (!windowObject.walkme_object) {
      windowObject.walkme_object = {};
    }
    windowObject.walkme_object.appName = walkmeData.appName;
    windowObject.walkme_object.defaultEnterprise = walkmeData.defaultEnterprise;
    windowObject.walkme_object.defaultStore = walkmeData.defaultStore;
    windowObject.walkme_object.userName = walkmeData.userName;
    windowObject.walkme_object.user_access = walkmeData.user_access;
  }
};
