/* eslint-disable @typescript-eslint/restrict-template-expressions, 
@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call */
import { createApi } from '@reduxjs/toolkit/query/react';
import { chunk } from 'lodash';
import apiEndPoints from '../../../fixtures/apiEndPoints';
import {
  CMFNumberDetails,
  CMFNumbersResponseType,
  DMSConfigurationType,
  DMSUser,
  DMSUserPayload,
  DmsBoxMappingInfo,
  DmsBoxMappingsResponseType,
  ExternalAttributeBaseInfo,
  ExternalAttributeInfo,
  ExternalAttributesDetails,
  ExternalAttributesResponseType,
  PutDMSConfigurationPayload
} from '../types/external-attributes-types';
import { CNumberInfo } from '../types/store-service-types';

import baseQuery from './baseQuery';
import { SelectedCNumber } from '../admin-service/types';

const getbBaseUrl = () => {
  let baseUrl = '';
  const locationurl = window.location.hostname;
  const matches =
    locationurl.match(
      /(www-dev)|(www-dit)|(www-qa)|(www-stage)|(www-perf)|(localhost)|(app-dms-cam-ui.app-stage.connectcdk.com)|(admin-stage)|(admin-dev)/g
    ) || '';

  if (matches?.length > 0) {
    baseUrl = 'dev.connectcdk.com';
  } else {
    baseUrl = 'connectcdk.com';
  }
  return baseUrl;
};

export const eisExternalAttributesServiceAPI = createApi({
  reducerPath: 'eisExternalAttribute',
  baseQuery,
  tagTypes: ['DMS_USER'],
  endpoints: builder => ({
    getCNumbers: builder.query<SelectedCNumber[], string>({
      query: (organizationId: string) =>
        `${apiEndPoints.getDmsBoxMappings}?organizationIds=${organizationId}`,
      transformResponse: (response: DmsBoxMappingsResponseType) => {
        let responseWithCNumbers = response.dmsBoxMappings.map(
          dmsBoxMappingInfo => ({
            label: dmsBoxMappingInfo.cnumber,
            value: dmsBoxMappingInfo.cnumber,
            alias: dmsBoxMappingInfo.alias ? `(${dmsBoxMappingInfo.alias})` : ''
          })
        );
        responseWithCNumbers = [
          { label: 'All', value: 'All', alias: '' },
          ...responseWithCNumbers
        ];
        return responseWithCNumbers;
      }
    }),
    getCNumberValidCheck: builder.mutation<CNumberInfo, string>({
      query: cNumber => ({
        url: `${apiEndPoints.getDmsBoxes(cNumber)}`,
        method: 'GET'
      })
    }),
    getCmfNumbers: builder.query<CMFNumberDetails, string>({
      query: (storeId: string) =>
        `${apiEndPoints.getCMFNumbers}?storeIds=${storeId}`,
      transformResponse: (response: CMFNumbersResponseType) => {
        let cmfNumbers: any = {};
        const cmfNumbersList = response.cmfs.map(cmf => ({
          ...cmf,
          externalSystemId: cmf.cmfNumber
        }));
        if (cmfNumbersList && cmfNumbersList.length > 0) {
          cmfNumbers = {
            cmfNumber: cmfNumbersList
          };
        }
        return cmfNumbers;
      }
    }),
    getExternalAttributes: builder.query<ExternalAttributesDetails, string>({
      query: (organizationId: string) =>
        `${apiEndPoints.getExternalAttributes}?organizationUnitIds=${organizationId}`,
      transformResponse: (response: ExternalAttributesResponseType) => {
        const externalAttributes: Record<any, ExternalAttributeBaseInfo[]> = {};

        response.externalAttributes.forEach(externalAttribute => {
          const externalAttributeType = externalAttribute.type;
          let externalAttributesList: ExternalAttributeBaseInfo[] = [];
          if (externalAttributes[externalAttributeType]) {
            externalAttributesList = externalAttributes[externalAttributeType];
          }
          const externalAttributeData = {
            ...externalAttribute,
            externalSystemId: externalAttribute.value
          };
          externalAttributesList.push(externalAttributeData);
          externalAttributes[externalAttributeType] = externalAttributesList;
        });
        return {
          externalSystemIdentifiers: externalAttributes
        };
      }
    }),

    getStoreExternalAttributes: builder.query<
      ExternalAttributesResponseType,
      string
    >({
      query: (organizationId: string) =>
        `${apiEndPoints.getExternalAttributes}?organizationUnitIds=${organizationId}`
    }),
    getStoreDMSBoxMappings: builder.query<any, string>({
      query: (storeId: string) =>
        `${apiEndPoints.getDmsBoxMappings}?organizationIds=${storeId}`,
      transformResponse: (response: DmsBoxMappingsResponseType) => {
        let dmsBoxMappings: any = {};
        if (
          response &&
          response.dmsBoxMappings &&
          response.dmsBoxMappings.length > 0
        ) {
          dmsBoxMappings = {
            driveIpAddress: [
              {
                externalSystemId: response.dmsBoxMappings[0].ipAddress,
                status: 'ACTIVE'
              }
            ],
            dmsCnumber: [
              {
                externalSystemId: response.dmsBoxMappings[0].cnumber,
                status: 'ACTIVE'
              }
            ]
          };
        }
        return dmsBoxMappings;
      }
    }),
    getOrganizationsDMSBoxMappings: builder.query<
      DmsBoxMappingsResponseType,
      string
    >({
      queryFn: async (
        organizationIds,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const organizationList: string[][] = chunk(
          organizationIds.split(','),
          50
        );
        const orgDmsBoxMappingsCollection: DmsBoxMappingInfo[] = [];
        const results = await Promise.all(
          organizationList.map((orgIds: string[]) =>
            fetchWithBQ(
              `${apiEndPoints.getDmsBoxMappings}?organizationIds=${orgIds.join(
                ','
              )}`
            )
          )
        );
        const orgDmsBoxMappingsData = orgDmsBoxMappingsCollection.concat(
          ...results
            .map(result => result.data as DmsBoxMappingsResponseType)
            .map(d => d.dmsBoxMappings)
        );
        return {
          data: {
            dmsBoxMappings: orgDmsBoxMappingsData
          }
        };
      }
    }),
    getCmfNumbersBulk: builder.query<CMFNumberDetails, any>({
      query: (enterpriseId: string) =>
        `${apiEndPoints.getCMFNumbers}?enterpriseId=${enterpriseId}`,
      transformResponse: (response: CMFNumbersResponseType) => {
        let cmfNumbers: any = {};
        const cmfNumbersList = response.cmfs.map(cmf => ({
          ...cmf,
          externalSystemId: cmf.cmfNumber
        }));
        if (cmfNumbersList && cmfNumbersList.length > 0) {
          cmfNumbers = {
            cmfNumber: cmfNumbersList
          };
        }
        return cmfNumbers;
      }
    }),
    getDmsBoxMappingsByCNumber: builder.query<DmsBoxMappingsResponseType, any>({
      query: ({ cNumber, organizationType }) => {
        const orgTypeQuery = organizationType
          ? `&organizationType=${organizationType}`
          : '';
        return `${apiEndPoints.getDmsBoxMappings}?cNumber=${cNumber}${orgTypeQuery}`;
      }
    }),
    getExternalAttributesByIds: builder.query<
      ExternalAttributesResponseType,
      any
    >({
      queryFn: async (
        { organizationIds, types },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const typesQuery = types ? `&types=${types}` : '';
        const organizationList: string[][] = chunk(
          organizationIds.split(','),
          50
        );
        const orgExtAttribDataCollection: ExternalAttributeInfo[] = [];
        const results = await Promise.all(
          organizationList.map((orgIds: string[]) =>
            fetchWithBQ(
              `${
                apiEndPoints.getExternalAttributes
              }?organizationUnitIds=${orgIds.join(',')}${typesQuery}`
            )
          )
        );
        const orgExtAttribData = orgExtAttribDataCollection.concat(
          ...results
            .map(result => result.data as ExternalAttributesResponseType)
            .map(d => d.externalAttributes)
        );
        return {
          data: {
            externalAttributes: orgExtAttribData
          }
        };
      }
    }),
    getDepartmentDMSBoxMappings: builder.query<any, string>({
      query: (departmentId: string) =>
        `${apiEndPoints.getDmsBoxMappings}?organizationIds=${departmentId}`,
      transformResponse: (response: DmsBoxMappingsResponseType) => {
        let dmsBoxMappings: any = {};
        if (
          response &&
          response.dmsBoxMappings &&
          response.dmsBoxMappings.length > 0
        ) {
          dmsBoxMappings = {
            driveIpAddress: [
              {
                externalSystemId: response.dmsBoxMappings[0].ipAddress,
                status: 'ACTIVE'
              }
            ],
            deptDmsCnumber: [
              {
                externalSystemId: response.dmsBoxMappings[0].cnumber,
                status: 'ACTIVE',
                organizationId: response.dmsBoxMappings[0].organizationId
              }
            ]
          };
        }
        return dmsBoxMappings;
      }
    }),
    getDMSUserBySimpleID: builder.query<
      DMSUser,
      { cNumber: string; userId: string }
    >({
      query: ({ cNumber, userId }) => {
        const protocol = 'https';
        const restPath = 'api.php/Admin';
        const baseUrl = getbBaseUrl();
        return `${protocol}://${cNumber}.drive.${baseUrl}/${restPath}/Users?cdksid=${userId}`;
      },
      providesTags: ['DMS_USER']
    }),
    getDMSUserByDMSUserID: builder.query<
      DMSUser,
      { cNumber: string; userId: string }
    >({
      query: ({ cNumber, userId }) => {
        const protocol = 'https';
        const restPath = 'api.php/Admin';
        const baseUrl = getbBaseUrl();
        return `${protocol}://${cNumber}.drive.${baseUrl}/${restPath}/Users/${userId}`;
      },
      providesTags: ['DMS_USER']
    }),
    getDMSInformationByCNumber: builder.query<
      any,
      { cNumber: string; type: string }
    >({
      query: ({ cNumber, type }) => {
        const protocol = 'https';
        const restPath = 'api.php/Admin';
        const baseUrl = getbBaseUrl();
        return `${protocol}://${cNumber}.drive.${baseUrl}/${restPath}/${type}`;
      }
    }),

    postLegacyDMSUser: builder.mutation<void, DMSUserPayload>({
      query: ({ dmsUser }) => {
        const protocol = 'https';
        const restPath = 'api.php/Admin';
        const baseUrl = getbBaseUrl();
        return {
          url: `${protocol}://${dmsUser?.cNumber}.drive.${baseUrl}/${restPath}/Users/${dmsUser?.dmsUserDetails?.Id}`,
          method: 'POST',
          body: dmsUser?.dmsUserDetails
        };
      },
      invalidatesTags: ['DMS_USER']
    }),
    getDMSConfigurations: builder.query<DMSConfigurationType, string>({
      query: (organizationId: string) =>
        `${apiEndPoints.getDmsBoxMappings}?organizationIds=${organizationId}`
    }),
    putDMSConfigurations: builder.mutation<
      void,
      { body: PutDMSConfigurationPayload; cnumber: string }
    >({
      query: ({ body, cnumber }) => ({
        url: `${apiEndPoints.putDMSConfiguration(cnumber)}`,
        method: 'PUT',
        body
      })
    })
  })
});

export const {
  useGetCNumbersQuery,
  useGetCNumberValidCheckMutation,
  useGetCmfNumbersQuery,
  useGetExternalAttributesQuery,
  useGetStoreExternalAttributesQuery,
  useGetStoreDMSBoxMappingsQuery,
  useGetOrganizationsDMSBoxMappingsQuery,
  useGetCmfNumbersBulkQuery,
  useGetDmsBoxMappingsByCNumberQuery,
  useGetExternalAttributesByIdsQuery,
  useGetDepartmentDMSBoxMappingsQuery,
  useGetDMSUserBySimpleIDQuery,
  useGetDMSUserByDMSUserIDQuery,
  useGetDMSInformationByCNumberQuery,
  usePostLegacyDMSUserMutation,
  useGetDMSConfigurationsQuery,
  usePutDMSConfigurationsMutation
} = eisExternalAttributesServiceAPI;
