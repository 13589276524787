/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import apiEndPoints from '../../../fixtures/apiEndPoints';
import {
  PostGroupDataPayload,
  PutGroupPayload,
  PostGroupOrEnterPriseDataPayload,
  DeleteGroupOrEnterPriseDataPayload,
  GroupDataResponse,
  GroupType
} from '../types/group-service-types';

const eisHierarchyeServiceBasePath: string =
  process.env.REACT_APP_NEBULA_API_BASE_URL || '';

export const eisHierarchyAPI = createApi({
  reducerPath: 'eisHierarchy',
  baseQuery,
  endpoints: builder => ({
    getGroupData: builder.query<GroupDataResponse, string>({
      query: (enterpriseId: string) =>
        `${eisHierarchyeServiceBasePath}${apiEndPoints.getGroupData(
          enterpriseId
        )}`
    }),
    getGroupDetails: builder.query<
      GroupDataResponse,
      { enterpriseId: string; index: number; itemsPerPage: number }
    >({
      query: ({ enterpriseId, index, itemsPerPage = 50 }) =>
        `${eisHierarchyeServiceBasePath}${apiEndPoints.getGroupDetails({
          enterpriseId,
          index,
          itemsPerPage
        })}`
    }),

    postGroupData: builder.mutation<void, PostGroupDataPayload>({
      query: body => ({
        url: `${apiEndPoints.postGroupData(body.enterpriseId)}`,
        method: 'POST',
        body: body.payload
      })
    }),
    putGroupData: builder.mutation<void, PutGroupPayload>({
      query: body => ({
        url: `${apiEndPoints.putGroupData(body.enterpriseId, body.groupId)}`,
        method: 'PUT',
        body: body.payload
      })
    }),
    putOrganizationData: builder.mutation<
      void,
      PostGroupOrEnterPriseDataPayload
    >({
      query: body => ({
        url: `${apiEndPoints.putOrganizationData(
          body.enterpriseId,
          body.groupId
        )}`,
        method: 'PUT',
        body: body.payload
      })
    }),
    deleteGroupData: builder.mutation<void, DeleteGroupOrEnterPriseDataPayload>(
      {
        query: body => ({
          url: `${eisHierarchyeServiceBasePath}${apiEndPoints.deleteGroupData(
            body.enterpriseId,
            body.groupId
          )}`,
          method: 'Delete',
          body
        })
      }
    ),
    getGroupTypes: builder.query<GroupType[], void>({
      query: () =>
        `${eisHierarchyeServiceBasePath}${apiEndPoints.getGroupTypes}`
    }),
    getGroupById: builder.query<
      GroupDataResponse,
      { enterpriseId: string; id: string }
    >({
      query: ({ enterpriseId, id }) =>
        `${eisHierarchyeServiceBasePath}${apiEndPoints.getGroupById({
          enterpriseId,
          id
        })}`
    })
  })
});

export const {
  useGetGroupDataQuery,
  useGetGroupDetailsQuery,
  useDeleteGroupDataMutation,
  usePutOrganizationDataMutation,
  usePutGroupDataMutation,
  usePostGroupDataMutation,
  useGetGroupTypesQuery,
  useGetGroupByIdQuery
} = eisHierarchyAPI;
