import { ExpandableList } from '../ExpandableList';

export const ExpandableSuccessMessage = (
  message: string,
  data: string[]
): JSX.Element => (
  <div>
    {message}
    <ExpandableList data={data} sliceBy={5} />
  </div>
);

export default ExpandableSuccessMessage;
